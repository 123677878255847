import { Injectable, inject } from "@angular/core";
import { ToastController } from '@ionic/angular/standalone';


@Injectable({ providedIn: 'root', deps: [ToastController] })
export class MessageController {
    private toastController = inject(ToastController);
    async error(message: string) {
        const toast = await this.toastController.create({
            message: message,
            duration: 1500,
            position: 'top',
            color: 'danger'
        });
        await toast.present();
    }
    async warning(message: string) {
        const toast = await this.toastController.create({
            message: message,
            duration: 1500,
            position: 'top',
            color: 'warning'
        });
        await toast.present();
    }
    async info(message: string) {
        const toast = await this.toastController.create({
            message: message,
            duration: 1500,
            position: 'top',
            color: 'info'
        });
        await toast.present();
    }
    async success(message: string) {
        const toast = await this.toastController.create({
            message: message,
            duration: 1500,
            position: 'top',
            color: 'success'
        });
        await toast.present();
    }
}


